.chatbot__main-div {
	border-radius: 20px;
	width: 400px;
	position: absolute;
	bottom: 10px;
	right: 15px;
	height: 500px;
	z-index: 1;
	overflow: hidden;
	box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
}

.chatbot__main-formDiv {
	position: absolute;
	bottom: 5px;
	left: 6px;
	background-color: white;
	padding-top: 10px;
}

#newMessage {
	padding: 10px;
	border-radius: 20px;
	width: 320px;
	border: 1px solid #ccc;
	outline: none;
	box-shadow: rgb(0 0 0 / 21%) 0px 0px 8px;
}

.chatbot__main-newMessagediv {
	display: flex;
}

#chatbot__main-paperIcon {
	padding: 8px 12px;
	border-radius: 20px;
	background-color: #5953e4;
	margin-left: 5px;
	height: fit-content;
	position: relative;
	box-shadow: rgb(0 0 0 / 35%) 0px 1px 8px;
}

.chatbot__dialog {
	display: flex;
	flex-direction: row;
	margin-left: 15px;
	margin-top: 1px;
	cursor: pointer !important;
}

svg {
	cursor: pointer !important;
}

#chatbot__main-botIcon svg {
	width: 20px;
	height: 20px;
	color: white;
	background-color: #5953e4;
	border-radius: 50%;
	padding: 5px;
}

.chatbot__dialog_message {
	background-color: #e9e9e9;
	border-radius: 30px;
	padding: 12px 15px 0px 12px;
	margin-left: 10px;
	max-width: 270px;
}

p {
	font-family: system-ui;
	font-size: 15px;
}

#chatbot__main-userDialogue {
	flex-direction: row-reverse;
}

#chatbot__main-userIcon svg {
	width: 35px;
	height: 28px;
	color: coral;
}

#chatbot__main-userDialogue {
	margin-right: 10px;
}

#chatbot__main-userMessage {
	margin-right: 5px;
	background-color: #5953e4;
}

#chatbot__main-userMessage p {
	color: white;
}

.chatbot__main-chatScreen {
	height: 78%;
	max-height: 78%;
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;
	margin-top: 40px;
}

.chatbot__main-chatScreen::-webkit-scrollbar {
	display: none;
}

#newMessage::-webkit-scrollbar {
	display: none;
}

iframe {
	display: none;
}

.chatbot__main-header {
	position: absolute;
	top: 0;
	height: 50px;
	width: 100%;
	background-color: #5953e4;
	text-align: center;
}

.chatbot__main-header h4 {
	color: white;
	font-family: monospace;
	margin-right: 5px;
	margin-top: 16px;
}

#chatbot__main-paperIcon svg {
	color: white;
	width: 13px;
}

.chatbot__main-chatDialogues {
	margin-top: 34px;
	padding-bottom: 5px;
}

#chatbot__main-botMessage {
	border-top-left-radius: 0;
	box-shadow: rgb(0 0 0 / 35%) 0px 1px 8px;
}

#chatbot__main-userMessage {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
	box-shadow: rgb(0 0 0 / 35%) 0px 1px 8px;
}

#chatbot__main-botDialogue {
	margin-top: 10px;
}

/* #chatbot__main-online{
	position: absolute;
	border: 1px solid green;
	background-color: green;
	border-radius: 50%;
	width: 5px;
	height: 5px;
} */

.chatbot__dialog_message p {
	margin-top: 0;
}
